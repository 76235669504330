import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18next
  .use(initReactI18next)
  .use(Backend) // Registering the back-end plugin
  .use(LanguageDetector) // Registering the detection plugin
  .init({
    supportedLngs: ["en", "he"],
    preload: ["en", "he"],
    fallbackLng: "en",
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindI18nStore: "added removed",
      useSuspense: true,
    },

    debug: process.env.NODE_ENV === "development",
  });
export default i18next;
