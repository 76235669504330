import React from "react";
import { ITabData } from "./AssessmentResult";
import "./AssessmentResultTab.scss";

interface IAssessmentResultTabProps {
  tabName: string;
  currentPathTabs: Array<ITabData>;
}

export default function AssessmentResultTab(props: IAssessmentResultTabProps) {
  const selectedTab = props.currentPathTabs.find(
    (tab: ITabData) => tab.name === props.tabName
  );

  // Either selected tab or default if navigated directly to this.
  const content = selectedTab
    ? selectedTab.content
    : props.currentPathTabs[0].content;

  return (
    <div id="assessment-result-tab">
      <ul className="asssement-content">
        {content.map((tabContent: string) => (
          <li key={tabContent}>{tabContent}</li>
        ))}
      </ul>
    </div>
  );
}
