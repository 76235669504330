import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Head from "../../shared/Head";
import pregroll from "../../shared/images/icon-preg-reversed.png";
import preg from "../../shared/images/icon-preg.png";
import { getRiskLevel } from "../../utils/index";
import Assessment from "./Assessment";
import AssessmentPopup from "./AssessmentPopup";
import "./index.scss";

export interface IAssessmentSelection {
  respi_distress?: string;
  appearence?: string;
  risk_factors?: string;
  o2_saturation?: string;
  respiratory_rate?: string;
}
export interface IControlData {
  assessments: Array<IAssessmentData>;
  rules: Array<IAssessmentRules>;
}
export interface IAssessmentData {
  tag: string;
  question: string;
  tooltip: string;
  choices: Array<IAssessmentChoice>;
}
export interface IAssessmentChoice {
  text: string;
  val: string;
}
export interface IAssessmentRules {
  answers: Object;
  path: string;
}

export default function AssessmentTool() {
  const { t, i18n } = useTranslation();
  const controlData = require(`../../ControlFiles/${i18n.language}/control.json`);
  const assessmentsData = controlData["assessments"];

  const [assessmentState, setAssessmentState] = useState({
    respi_distress: undefined,
    appearence: undefined,
    risk_factors: undefined,
    o2_saturation: undefined,
    respiratory_rate: undefined,
  });
  const [pregnantState, setPregnantState] = useState(false);
  const [pediatricState, setPediatricState] = useState(false);

  const handleAssessmentSelection = () => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setAssessmentState({ ...assessmentState, [name]: value });
    };
  };
  const riskLevel = getRiskLevel(controlData, assessmentState);

  return (
    <div id="assessment-tool">
      <Head description="Some description of Assessment Tool" />
      <AssessmentPopup />
      <h1>{t("assessment-tool.title")}</h1>
      {assessmentsData.map((assessmentData: IAssessmentData) => (
        <Assessment
          key={assessmentData.tag}
          tag={assessmentData.tag}
          question={assessmentData.question}
          tooltip={assessmentData.tooltip}
          choices={assessmentData.choices}
          onSelect={handleAssessmentSelection}
        />
      ))}
      {riskLevel ? (
        <div className="form-group risk-level">
          <h2>{t("assessment-tool.calculated-risk-level")}</h2>
          <Link to={`/${i18n.language}/assessment-result/${riskLevel}`}>
            <button
              className="btn btn-lg btn-block btn-risk"
              id={t(`common.${riskLevel}`)}
            >
              {t(`common.${riskLevel}`)}
              <i className="fas fa-chevron-right"></i>
            </button>
          </Link>
        </div>
      ) : (
        <div className="form-group risk-level">
          <h2>{t("assessment-tool.calculated-risk-level")}</h2>
          <Link to={`/${i18n.language}/assessment-result/moderate`}>
            <button
              className="btn btn-lg btn-block btn-risk"
              id={t("common.moderate")}
            >
              Unknown ({t("common.moderate")})
              <i className="fas fa-chevron-right"></i>
            </button>
          </Link>
        </div>
      )}
      <div
        className="btn-group btn-group-toggle btn-group-special fixed-bottom"
        role="group"
        aria-label="Pregnant or Pediatric"
        data-toggle="buttons"
      >
        <label className="btn btn-light btn-special">
          <input
            type="checkbox"
            onChange={() => setPregnantState(!pregnantState)}
          />
          <img src={preg} alt="Pregnant icon" className="pregicon" />
          <img src={pregroll} alt="Pregnant icon" className="pregicon-active" />
          <span className="sr-only">{t("common.pregnant")}</span>
        </label>
        <label className="btn btn-light btn-special">
          <input
            type="checkbox"
            onChange={() => setPediatricState(!pediatricState)}
          />
          <i className="fas fa-child"></i>
          <span className="sr-only">{t("common.pediatric")}</span>
        </label>
      </div>
    </div>
  );
}
