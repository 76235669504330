import React from "react";
import { Helmet } from "react-helmet-async";
const { titleizePath } = require("@mountainpasstech/mpjs/str"); // We need a ts declaration file for this module.

export interface IHeadProps {
  description: string;
}
export default function Head(props: IHeadProps) {
  const pathname =
    window.location.pathname === "/" ? "Home" : window.location.pathname;
  return (
    <Helmet>
      <meta name="description" content={props.description} />
      <title>{titleizePath(pathname, window.location.host)}</title>
    </Helmet>
  );
}
