import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import "./AssessmentResult.scss";
import AssessmentResultTab from "./AssessmentResultTab";

export interface IPathData {
  name: string;
  url: string;
}
export interface ITabData {
  name: string;
  content: Array<string>;
}
export interface IParams {
  path: string;
}

export function AssessmentResult() {
  const { i18n } = useTranslation();
  const controlData = require(`../../ControlFiles/${i18n.language}/control.json`);
  const paths = controlData["paths"];
  const params: IParams = useParams();

  const [currentTab, setCurrentTab] = useState("");

  const currentPath =
    paths.find((path: IPathData) => path.url === params.path) || paths[1];

  const handleOnTabClick = (tabName: string) => setCurrentTab(tabName);

  return (
    <div id="assessment-result" className={currentPath.url}>
      <ul id="paths" className="nav nav-tabs nav-justified" role="group">
        {paths.map((path: IPathData) => (
          <li className="nav-item" key={path.url}>
            <NavLink
              key={path.url}
              className="nav-link"
              id={path.url}
              to={`/${i18n.language}/assessment-result/${path.url}`}
            >
              {path.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <ul id="tabs" className="nav nav-pills nav-fill">
        {currentPath.tabs.map((tab: ITabData) => (
          <li className="nav-item" key={tab.name}>
            <NavLink
              isActive={() => tab.name === currentTab}
              key={tab.name}
              className="nav-link"
              to="#"
              onClick={() => handleOnTabClick(tab.name)}
            >
              {tab.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <AssessmentResultTab
        currentPathTabs={currentPath.tabs}
        tabName={currentTab}
      />
    </div>
  );
}
