import {
  IAssessmentRules,
  IAssessmentSelection,
  IControlData,
} from "../screens/AssessmentTool";

const R = require("ramda");
const { pick } = require("@mountainpasstech/mpjs/xs");

/**
 * @param {object} controlData - The parsed JSON control file contents.
 * @param {object} selections - An object of "answers" to the assessment tool.
 * @returns {String} - The assessement as a string. "Mild" etc. given
 * a choice and assessment data.
 */
export function getRiskLevel(
  controlData: IControlData,
  selections: IAssessmentSelection
): string {
  return controlData.rules.reduce(
    // The rules prop is an array, reduce is a callback
    // that takes the accumulator (blank string -> risk level)
    // and the current element--the rule.
    (riskLevel: string, rule: IAssessmentRules) => {
      // If there's a value in selections and the risk level
      // is the rule.path then the answer is the risk level.
      R.equals(
        rule.answers,
        pick((_: any, v: any) => v, selections)
      ) && (riskLevel = rule.path);
      return riskLevel;
    },
    ""
  );
}
