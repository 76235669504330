import React from "react";
export const overview = (
  <div>
    <h1>דף מידע על סקירה כללית</h1>
    <p>
      לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קונסקטורר אדיפיסינג
      אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום אט
      דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק. קונדימנטום
      קורוס בליקרה, נונסטי קלובר בריקנה סטום, לפריקך תצטריק לרטי.
    </p>
    <p>
      נולום ארווס סאפיאן - פוסיליס קוויס, אקווזמן קוואזי במר מודוף. אודיפו
      בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף גולר מונפרר סוברט לורם
      שבצק יהול, לכנוץ בעריר גק ליץ, ושבעגט ליבם סולגק. בראיט ולחת צורק מונחף,
      בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק? לתיג ישבעס.
    </p>
    <ul>
      <li>
        <a href="/#">מאמר סקירה כללית 1</a>
      </li>
      <li>
        <a href="/#">מאמר סקירה כללית 2</a>
      </li>
      <li>
        <a href="/#">מאמר סקירה כללית 3</a>
      </li>
    </ul>
  </div>
);
