import React, { useState } from "react";

export default function Auth() {
  const [password, setPassword] = useState("");

  const handleOnSubmit = () => {
    if (password === "!covid") {
      localStorage.setItem("covidAuth", "true");
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <form onSubmit={handleOnSubmit}>
        <label>
          A password is required to access this site:
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
}
