import React from "react";

export const medications = (
  <div>
    <h1>Medications Info Page</h1>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin gravida
      tellus risus. Sed in quam feugiat, feugiat lorem ac, condimentum ipsum.
      Donec in nisl eu ligula fringilla tristique id posuere arcu. Phasellus
      molestie turpis vel ullamcorper placerat. Morbi id dui nec ante mollis
      ornare. Donec tempor cursus ante, hendrerit gravida mauris tincidunt
      tempor. Quisque viverra ac nulla non aliquet. Praesent erat ligula,
      faucibus eget felis ut, accumsan posuere justo.
    </p>
    <p>
      Nunc vehicula tellus a odio aliquet pulvinar. Cras condimentum cursus
      lobortis. Ut neque tellus, ultrices semper metus vitae, tincidunt rutrum
      urna. Fusce mattis condimentum nisl, id consectetur urna aliquam vel. Duis
      lacinia, justo sed molestie aliquet, leo sem sodales dolor, id volutpat
      massa purus sit amet nulla. Vestibulum in gravida elit. Nullam ultrices,
      enim ac auctor pulvinar, orci felis mattis mauris, a consequat quam magna
      ac odio.
    </p>
    <ul>
      <li>
        <a href="/#">Medications Article 1</a>
      </li>
      <li>
        <a href="/#">Medications Article 2</a>
      </li>
      <li>
        <a href="/#">Medications Article 3</a>
      </li>
    </ul>
  </div>
);
