import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { IAssessmentChoice, IAssessmentData } from "../AssessmentTool";

export interface IAssessmentProps extends IAssessmentData {
  onSelect: Function;
}

export default function Assessment(props: IAssessmentProps) {
  return (
    <div id="Assessment" className="Assessment form-group">
      <h2>
        {props.question}
        {/* Using the function form of OverlayTrigger avoids a React.findDOMNode call, 
          for those trying to be strict mode compliant. "buttonProps" are dummy props that dont do anything,
          but are neccesary for the function form call of OverlayTrigger */}
        <OverlayTrigger
          transition={false}
          placement="top"
          overlay={
            <Tooltip id={`${props.tag}-tooltip`}>{props.tooltip}</Tooltip>
          }
        >
          {(buttonProps) => (
            <button className="btn-tooltip" {...buttonProps}>
              <i className="fas fa-question-circle"></i>
            </button>
          )}
        </OverlayTrigger>
      </h2>

      <div
        className="btn-group btn-group-lg btn-group-toggle"
        role="group"
        aria-label="Assessment Choice"
        data-toggle="buttons"
      >
        {props.choices.map((choice: IAssessmentChoice) => (
          <label className="btn btn-light" key={choice.text}>
            <input
              type="radio"
              value={choice.val}
              name={props.tag}
              onChange={props.onSelect()}
            />
            {choice.text}
          </label>
        ))}
      </div>
    </div>
  );
}
