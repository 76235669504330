import React from "react";
import { useLocation } from "react-router-dom";
import "./index.scss";
import { Nav } from "./Nav";

export function Header() {
  let location = useLocation();
  // Dont show navbar on homepage
  return location.pathname === "" ? null : (
    <header className="App-header">
      <Nav />
    </header>
  );
}
