import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LanguageSwitcher } from "./LanguageSwitcher";

export function Nav() {
  const { t, i18n } = useTranslation();

  interface INavItem {
    name: string;
    url: string;
  }
  const navItems = [
    { name: t("common.medications"), url: "medications" },
    { name: t("common.pediatrics"), url: "pediatrics" },
    { name: t("common.pregnancy"), url: "pregnancy" },
    { name: t("common.palliation"), url: "palliation" },
    { name: t("common.overview"), url: "overview" },
    { name: t("common.resources"), url: "resources" },
  ];

  return (
    <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-primary">
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i className="fas fa-bars"></i>
      </button>
      <Link to={`/${i18n.language}`} className="navbar-brand">
        Covid19AcuteMx
      </Link>
      <Link
        className="navbar-navigator"
        to={`/${i18n.language}/navigator/moderate`}
      >
        <i className="fas fa-project-diagram"></i>
      </Link>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to={`/${i18n.language}`} className="nav-link">
              {t("common.home")}
            </Link>
          </li>
          <li className="nav-item">
            <Link to={`/${i18n.language}/assessment-tool`} className="nav-link">
              {t("common.assessment-tool")}
            </Link>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/"
              id="navbarDropdownLabel"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {t("common.result")}
            </a>
            <div
              className="dropdown-menu"
              aria-labelledby="navbarDropdownLabel"
            >
              {[
                { name: t("common.mild"), url: "mild" },
                { name: t("common.moderate"), url: "moderate" },
                { name: t("common.severe"), url: "severe" },
              ].map((navItem: INavItem) => (
                <Link
                  key={navItem.url}
                  to={`/${i18n.language}/assessment-result/${navItem.url}`}
                  className="dropdown-item"
                >
                  {navItem.name}
                </Link>
              ))}
            </div>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/"
              id="navbarDropdownLabelInfo"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {t("common.information")}
            </a>
            <div
              className="dropdown-menu"
              aria-labelledby="navbarDropdownLabelInfo"
            >
              {navItems.map((navItem: INavItem) => (
                <Link
                  key={navItem.url}
                  to={`/${i18n.language}/info/${navItem.url}`}
                  className="dropdown-item"
                >
                  {navItem.name}
                </Link>
              ))}
            </div>
          </li>
          <LanguageSwitcher />
        </ul>
      </div>
    </nav>
  );
}
