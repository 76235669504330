import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export interface ILanguages {
  name: string;
  code: string;
}
export function LanguageSwitcher() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const history = useHistory();
  const languages = [
    {
      name: "English",
      code: "en",
    },
    {
      name: "עברית",
      code: "he",
    },
  ];

  const changeLanguageHandler = (event: any) => {
    const newLanguage = event.target.value;
    if (language !== newLanguage) {
      i18n.changeLanguage(newLanguage);
      setLanguage(newLanguage);
      // if user changes language while already on a page, replace the old language in the url with the new one
      let split_url = window.location.pathname.split("/");
      split_url[1] = newLanguage;
      history.push(split_url.join("/"));
    }
  };

  return (
    <div className="select">
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="/"
          id="navbarDropdownLabelInfo"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {t("common.language")}
        </a>
        <div
          className="dropdown-menu"
          aria-labelledby="navbarDropdownLabelInfo"
        >
          {languages.map((language: ILanguages) => (
            <button
              key={language.code}
              onClick={changeLanguageHandler}
              className="dropdown-item"
              value={language.code}
            >
              {language.name}
            </button>
          ))}
        </div>
      </li>
    </div>
  );
}
