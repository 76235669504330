import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import {
  IParams,
  IPathData,
  ITabData,
} from "../AssessmentTool/AssessmentResult";
import "./index.scss";

export function Navigator() {
  const { i18n } = useTranslation();
  const controlData = require(`../../ControlFiles/${i18n.language}/control.json`);
  const paths = controlData["paths"];
  const params: IParams = useParams();

  const currentPath =
    paths.find((path: IPathData) => path.url === params.path) || paths[1];

  return (
    <div id="navigator">
      <div id="paths" className="btn-toolbar" role="group">
        {paths.map((path: IPathData) => (
          <button type="button" className={`btn btn-lg`} key={path.url}>
            <Link to={`/${i18n.language}/navigator/${path.url}`}>
              {path.name}
            </Link>
          </button>
        ))}
      </div>

      <div className="accordion" id="tabsAccordion">
        {currentPath.tabs.map((tab: ITabData, index: number) => (
          <div className="card" key={tab.name}>
            <div className="card-header" id={`heading${tab.name}`}>
              <h2 className="mb-0">
                <button
                  className={`btn btn-link btn-block text-left ${
                    index === 0 ? "" : "collapsed"
                  }`}
                  type="button"
                  data-toggle="collapse"
                  data-target={`#collapse${tab.name}`}
                  aria-expanded={index === 0 ? "true" : "false"}
                  aria-controls={`collapse${tab.name}`}
                >
                  {tab.name}
                </button>
              </h2>
            </div>

            <div
              id={`collapse${tab.name}`}
              className={`collapse ${index === 0 ? "show" : ""}`}
              aria-labelledby={`heading${tab.name}`}
              data-parent="#tabsAccordion"
            >
              <div className="card-body">
                {tab.content.map((tabContent: string) => (
                  <li key={tabContent}>{tabContent}</li>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
