import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Auth from "./Auth";
import AssessmentTool from "./screens/AssessmentTool";
import { AssessmentResult } from "./screens/AssessmentTool/AssessmentResult";
import { CovidInfo } from "./screens/CovidInfo";
import { TopicContent } from "./screens/CovidInfo/TopicContent";
import Home from "./screens/Home";
import { Navigator } from "./screens/Navigator";
import { Header } from "./shared/Header";
// Careful here..order matters.
const $ = require("jquery");

// Bootstrap must have jQuery on window.
(window as any).$ = $;
(window as any).jQuery = $;

// Finally, we can load bootstrap.
require("bootstrap/dist/js/bootstrap.bundle");

const isAuthenticated = localStorage.getItem("covidAuth");

function App() {
  const { i18n } = useTranslation();
  const location = useLocation();
  // array of supported languages
  const languages = ["en", "he"];
  // get language from url
  const urlLanguage = location.pathname.split("/")[1];
  // check if urlLanguage is one of the supported languages
  // if yes check if the currently set language is different from the urlLanguage
  // if so change language to the url language
  // if not supported stick with currently set language
  const langaugeToSet = languages.includes(urlLanguage)
    ? urlLanguage
    : // i18n.language is the currently set language
      i18n.language;

  if (i18n.language !== langaugeToSet) {
    i18n.changeLanguage(langaugeToSet);
  }

  return isAuthenticated ? (
    <HelmetProvider>
      <div className="App container">
        <Header />
        <Switch>
          <Route path={`/${i18n.language}/assessment-tool`}>
            <AssessmentTool />
          </Route>
          <Route path={`/${i18n.language}/covid19-info`}>
            <CovidInfo />
          </Route>
          <Route path={`/${i18n.language}/assessment-result/:path`}>
            <AssessmentResult />
          </Route>
          <Route path={`/${i18n.language}/navigator/:path`}>
            <Navigator />
          </Route>
          <Route path={`/${i18n.language}/info/:topic`}>
            <TopicContent />
          </Route>
          <Route path={`/${i18n.language}`}>
            <Home />
          </Route>
          <Route path="*">{<Redirect to={`/${i18n.language}`} />}</Route>
        </Switch>

        {/* <Footer /> */}
      </div>
    </HelmetProvider>
  ) : (
    <Auth />
  );
}

export default App;
