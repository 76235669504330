import React, { ChangeEvent, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./index.scss";

/**
 * Deal with details of converting local storage to boolean. E.g.
 * null means nothing has been set, which means it should be true to
 * show modal etc.
 */
function getInitState() {
  const ls = localStorage.getItem("showPopup");
  return ls === null ? true : ls === "true";
}

export default function AssessmentPopup() {
  const [showPopup, setShowPopup] = useState(getInitState());

  // Handlers
  const handlePopupClick = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.checked
      ? localStorage.removeItem("showPopup")
      : localStorage.setItem("showPopup", "false");
  };
  const closeModal = (e: any) => {
    setShowPopup(false);
  };

  return (
    <Modal show={showPopup} animation={false}>
      <Modal.Body>
        <Modal.Title>General Reminders</Modal.Title>
        <ul>
          <li>Avoid Stethascope</li>
          <li>Wear PPE</li>
          <li>Patient Mask</li>
        </ul>
        <Modal.Title>New Patient</Modal.Title>
        <ul>
          <li>Take Symptoms</li>
          <li>Take Vitals</li>
          <li>Ask about risk factors</li>
          <li>Level of Hypoxia</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={closeModal}>
          Start Assessment
        </button>
        <label>
          <input
            type="checkbox"
            defaultChecked
            onChange={(e) => handlePopupClick(e)}
          />
          Show this screen before each assessment
        </label>
      </Modal.Footer>
    </Modal>
  );
}
