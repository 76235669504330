import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Head from "../../shared/Head";
import "./index.scss";

export default function Home() {
  const { t, i18n } = useTranslation();
  return (
    <div id="Home">
      <Head description="Some desc of home page" />
      <h2>Covid19AcuteMx</h2>
      <p>{t("home.description")}</p>
      <ul className="left-column">
        <h4>{t("common.information")}</h4>
        <p>
          <Link to={`/${i18n.language}/info/medications`}>
            {t("common.medications")}
          </Link>
        </p>
        <p>
          <Link to={`/${i18n.language}/info/pediatrics`}>
            {t("common.pediatrics")}
          </Link>
        </p>
        <p>
          <Link to={`/${i18n.language}/info/pregnancy`}>
            {t("common.pregnancy")}
          </Link>
        </p>
        <p>
          <Link to={`/${i18n.language}/info/palliation`}>
            {t("common.palliation")}
          </Link>
        </p>
        <p>
          <Link to={`/${i18n.language}/info/overview`}>
            {t("common.overview")}
          </Link>
        </p>
        <p>
          <Link to={`/${i18n.language}/info/resources`}>
            {t("common.resources")}
          </Link>
        </p>
        <p>
          <Link to={`/${i18n.language}/covid19-info`}>
            {t("common.covid-info")}
          </Link>
        </p>
      </ul>
      <div className="right-column">
        <h3>{t("common.tools")}</h3>
        <p>
          <Link to={`/${i18n.language}/assessment-tool`}>
            {t("common.assessment-tool")}
          </Link>
        </p>
      </div>
    </div>
  );
}
