import React from "react";
import Head from "../../shared/Head";
import "./index.scss";

export function CovidInfo() {
  return (
    <div id="covid-info">
      <Head description="Some description of Covid-19 Information" />
      <p>I am the Covid-19 Information page</p>
    </div>
  );
}
