import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export function TopicContent() {
  const params: any = useParams();
  const { i18n } = useTranslation();

  const topicContentFromParams = () => {
    const page = require(`../../Information/${i18n.language}/${params.topic}`);
    return page[`${params.topic.toLowerCase()}`].props.children;
  };

  return <div id="topic-content">{topicContentFromParams()}</div>;
}
